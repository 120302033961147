import { styled } from "styled-components";

import { Container as GridContainer } from "style/components/Page";

export const Overlay = styled.div.attrs<{
    $isVisible: boolean;
}>(() => ({}))`
    align-items: flex-start;
    background: var(--color-black-l95-o98);
    flex-flow: row;
    inset: 0;
    justify-content: center;
    position: fixed;
    ${(props) => (props.$isVisible ? "display: flex" : "display: none")};
    z-index: 500;
`;

export const Container = styled(GridContainer)`
    padding-bottom: 0;
    padding-top: 0;
`;
