import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import SearchFieldProps from "./SearchFieldProps";

const SearchField = loadable(
    (): Promise<DefaultComponent<SearchFieldProps>> =>
        import(/* webpackChunkName: "SearchField" */ "./SearchField"),
);
const SearchFieldLoader = (props: SearchFieldProps): ReactElement => (
    <SearchField {...props} />
);
export default SearchFieldLoader;
