import React, { ReactElement } from "react";
import isEqual from "react-fast-compare";
import { ThemeProvider } from "styled-components";

import SearchField from "components/SearchField";
import { useHeader } from "hooks";
import getTestId from "utils/getTestId";

import { Container, Overlay } from "./SearchOverlay.styled";
import SearchOverlayProps from "./SearchOverlayProps";

const SearchOverlay = ({
    className,
    searchPageUrl,
    l18n: { searchLabel, searchPlaceholder },
}: SearchOverlayProps): ReactElement => {
    const { searchOverlayVisible } = useHeader();

    return (
        <ThemeProvider
            theme={{
                theme: "lightgray",
                contentTheme: "black",
                actionTheme: "black",
            }}
        >
            <Overlay
                aria-hidden={searchOverlayVisible ? "false" : "true"}
                className={className}
                data-cy={getTestId("search-overlay")}
                $isVisible={!!searchOverlayVisible}
            >
                <Container>
                    <SearchField
                        searchPageUrl={searchPageUrl}
                        searchLabel={searchLabel}
                        searchPlaceholder={searchPlaceholder}
                        resetOnHide={true}
                    />
                </Container>
            </Overlay>
        </ThemeProvider>
    );
};

export default React.memo(SearchOverlay, isEqual);
